import React from 'react'
import './Policy.css'
import { Link } from 'react-router-dom'
const Refund = () => {
  return (
    <div className="policy-d1">
      <div className="policy-d2">
        <div className="policy-d3">
          <h1>REFUND & RETURN POLICY</h1>
          <h2>
            <Link to={"/"}>HOME</Link> / POLICY
          </h2>
        </div>
      </div>
      <div className="policy-d4">
        <div className="policy-d5">
          <div className="policy-d6">
            <h1>REFUND & RETURN POLICY</h1>
            <p>
              THANK YOU FOR SHOPPING AT Manpreet Singh FITNESS. THIS IS THE
              RETURNS AND REFUND POLICY.
              <br /> <br />
              PLEASE READ THIS POLICY CAREFULLY BEFORE MAKING ANY PURCHASES WITH
              Manpreet Singh FITNESS. SALES FOR ALL PRODUCTS AND SERVICES ARE
              FINAL. OUR DIGITAL PRODUCTS/SERVICES CANNOT BE RETURNED AND ARE
              THEREFORE NON-REFUNDABLE. OUR COACHING AND PERSONAL TRAINING
              SERVICES ARE WITHOUT GUARANTEE AND ALSO NON-REFUNDABLE. ANY
              PHYSICAL PRODUCT PURCHASED CAN BE REFUNDED IF ANY DEFECT IS FOUND.
              <br /> <br />
              OUR GOAL AT Manpreet Singh FITNESS IS TO PROVIDE YOU WITH THE
              BEST FITNESS TRAINING AND COACHING AVAILABLE. WE UNDERSTAND THAT
              THERE MAY BE TIMES WHEN YOU HAVE CONCERNS ABOUT YOUR PURCHASE AND
              MAY REQUIRE A REFUND. WE ARE HAPPY TO HEAR ANY CONCERNS YOU MAY
              HAVE, ALONG WITH ANY REASONS YOU FEEL THAT YOU SHOULD BE GRANTED A
              REFUND.
              <br /> <br />
              PLEASE CONTACT US AT INFO@ManpreetNijjar.COM FOR ANY OF THESE
              CONCERNS AND SOMEONE WILL RESPOND TO YOU WITHIN ONE TO TWO
              BUSINESS DAYS.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Refund